<template>
  <div class="productFourth">
    <div class="rateBox">
      <ul>
        <li>
          <span>美元今日汇率</span>
          <img class="firstImg" src="../../../static/product/currency_3.png" alt="">
          <span class="num">1</span>
          <span>美元</span>
          <span> = </span>
          <div>
            <p>
              <img src="../../../static/product/currency_2.png" alt="">
              <span>{{todayData.mj_gb}}</span>
              <span>港币</span>
            </p>
            <p class="divP">
              <img src="../../../static/product/currency_1.png" alt="">
              <span>{{todayData.mj_rmb}}</span>
              <span>人民币</span>
            </p>
          </div>
        </li>
        <li>
          <span>港币今日汇率</span>
          <img class="firstImg" src="../../../static/product/currency_2.png" alt="">
          <span class="num">1</span>
          <span>港币</span>
          <span> = </span>
          <div>
            <p>
              <img src="../../../static/product/currency_1.png" alt="">
              <span>{{todayData.gb_rmb}}</span>
              <span>人民币</span>
            </p>
            <p></p>
          </div>
        </li>
      </ul>
    </div>
    <p class="centerP">本平台的结算货币均为美元，为节省客户交易成本，平台将依照市场汇率而更新取款汇率。</p>
    <div class="detailTable">
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%;margin: 0 auto;"
        :header-cell-style="{textAlign: 'center',backgroundColor:'#e8edf1',color:'#393a39',fontSize:'18px'}"
        :header-row-style="{height:'75px'}"
        :cell-style="{ textAlign: 'center' }">
        <el-table-column
          prop="time"
          label=""
          min-width="20%">
        </el-table-column>
        <el-table-column
          prop="mj_gb"
          label="美元/港币"
          min-width="40%">
          <template slot-scope="scope">
            <span>1:{{scope.row.mj_gb}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="mj_rmb"
          label="美元/人民币"
          min-width="40%">
          <template slot-scope="scope">
            <span>1:{{scope.row.mj_rmb}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="gb_rmb"
          label="港币/人民币"
          min-width="40%">
          <template slot-scope="scope">
            <span>1:{{scope.row.gb_rmb}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <ul class="productBottom">
      <li>有关汇率细则如下：</li>
      <li>1.若客户以人民币进行取款时，需参考本公司公布的市场汇率作兑换;</li>
      <li>2.若客户注资时存入人民币，则根据第三方支付平台提供的汇率作兑换;</li>
      <li>3.若客户以港币作为资金存取时，需参考本公司公布的市场汇率作为兑换。</li>
      <li>本公司提供的汇率价格公开透明，绝不任意收取费用。</li>
    </ul>
  </div>
</template>

<script>
  import { stringify } from 'querystring';
import {TodayList,sevenDayList} from '../../../api/info'
export default {
  name:'productFourth',
  data() {
    return {
      // 近七日历史汇率
      tableData: [],
      // 今日汇率
      todayData:{},
    }
  },
  created(){
    this.initTodayList(stringify({type:2,isForSite:1,}));
    this.initSevenDayList(stringify({type: 2,days: 7}));
  },
  methods:{
    initTodayList(attr){
      TodayList(attr).then(res=>{
        if(res.IsSuccess == true){
          this.todayData = res.Message;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    initSevenDayList(attr){
      sevenDayList(attr).then(res=>{
        if(res.IsSuccess == true){
          this.tableData = res.Message;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .productFourth{
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 100px;
    .rateBox{
      width: 1220px;
      height: 400px;
      margin: 0 auto;
      border: 2px solid #e8edf1;
      display: flex;
      justify-content: center;
      align-items: center;
      ul{
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        li{
          width: 80%;
          margin: 0 auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #333333;
          font-weight: 600;
          .firstImg{
            margin: 0 13px 0 140px;
          }
          .num{
            width: 120px;
            height: 40px;
            border: 1px solid #dcdcdc;
            text-align: center;
            line-height: 42px;
          }
          span:nth-child(4){
            margin: 0 50px 0 13px;
          }
          div{
            margin-left: 60px;
            p{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              span:nth-child(2){
                display: inline-block;
                width: 120px;
                height: 40px;
                border: 1px solid #dcdcdc;
                text-align: center;
                line-height: 42px;
                margin: 0 15px;
              }
              span:nth-child(3){
                border: none;
              }
            }
            .divP{
              margin-top: 20px;
            }
          }
        }
      }
    }
    .centerP{
      width: 100%;
      text-align: center;
      color: #666666;
      margin-top: 15px;
    }
    .detailTable{
      width: 1222px;
      box-sizing: border-box;
      padding: 15px 0px 105px;
      margin: 100px auto 0;
      /deep/ .el-table__row{
        height: 80px;
      }
      /deep/ .el-table__row--striped{
        height: 75px;
      }
      /deep/ .el-table__row td:nth-child(1){
        font-weight: 600 !important;
      }
      /deep/ .el-table .cell {
        white-space: pre-line;
      }
      ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td{
        background: #f6f8fa;
      }
    }
    .productBottom{
      width: 1222px;
      margin: 0 auto;
      li{
        color: #949494;
        line-height: 30px;
      }
      li:nth-child(1){
        color: #333333;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .productFourth{
      width: 100%;
      box-sizing: border-box;
      padding-bottom: 50px;
      .rateBox{
        width: 98%;
        height: 260px;
        margin: 0 auto;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 0 5px;
        ul{
          height: 80%;
          li{
            width: 100%;
            img{
              width: 45px;
            }
            .firstImg{
              margin: 0 5px 0 5px
            }
            span{
              font-size: 12px;
              margin: 0 5px;
            }
            span:nth-child(4){
              margin: 0 5px 0 5px
            }
            .num{
              width: 80px;
              height: 28px;
              line-height: 28px;
              font-size: 12px;
            }
            div{
              margin-left: 10px;
              p{
                span:nth-child(2){
                  width: 80px;
                  height: 28px;
                  line-height: 28px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .centerP{
        width: 95%;
        margin: 10px auto 0;
        font-size: 12px;
      }
      /deep/.detailTable{
        width: 100%;
        margin: 30px auto 0;
        padding: 0px 5px 30px 5px;
        .el-table .cell{
          font-size: 13px !important;
          padding: 0 !important;
        }
        .el-table th, .el-table tr{
          height: 50px !important;
        }
        .el-table td, .el-table th{
          padding: 5px 0;
        }
      }
      .productBottom{
        width: 100%;
        box-sizing: border-box;
        padding: 0 5px;
        li:nth-child(1){
          font-size: 16px;
        }
        li{
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
</style>